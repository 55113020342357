"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createYmCounterPlugin = createYmCounterPlugin;
class YmCounter {
    counterId;
    constructor(counterId) {
        this.counterId = counterId;
    }
    init() {
        ym(this.counterId, "init", {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
            defer: true,
        });
    }
    setUserId(userId) {
        ym(this.counterId, "userParams", {
            UserID: userId,
        });
    }
    hit(path, referrer) {
        ym(this.counterId, "hit", path, { referer: referrer });
    }
}
function createYmCounterPlugin(Vue) {
    if (process.env.VUE_APP_YM_COUNTER_ID) {
        Vue.ymCounter = new YmCounter(Number(process.env.VUE_APP_YM_COUNTER_ID));
    }
}
