"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdTemplateType = exports.AppMetricaApiEventType = exports.TrackingSystem = exports.TrafficSourceCPIParams = exports.TrafficSourceMediaParams = exports.EventName = exports.BASE64_PARAM_KEY = exports.MRCStatus = exports.MRCStatusShort = exports.MediaEvent = exports.MediaEventShort = exports.BrandSafetyParamsKey = exports.BidRequestParamsKey = exports.PlayerRequestParamsKey = exports.DOMRequestParamsKey = exports.MediaTrafficSourceRequestParamsKey = exports.ExternalIdParamsKey = exports.CommonRequestParamsKey = exports.HitType = void 0;
var HitType;
(function (HitType) {
    HitType["HIT_CPI_CLICK"] = "HIT_CPI_CLICK";
    HitType["HIT_CPI_POSTBACK"] = "HIT_CPI_POSTBACK";
    HitType["HIT_MEDIA_PIXEL"] = "HIT_MEDIA_PIXEL";
    HitType["HIT_MEDIA_VPAID"] = "HIT_MEDIA_VPAID";
    HitType["HIT_MEDIA_POSTBACK"] = "HIT_MEDIA_POSTBACK";
    HitType["HIT_BRAND_SAFETY"] = "HIT_BRAND_SAFETY";
    HitType["HIT_CAPTCHA_RESULT"] = "HIT_CAPTCHA_RESULT";
})(HitType || (exports.HitType = HitType = {}));
var CommonRequestParamsKey;
(function (CommonRequestParamsKey) {
    CommonRequestParamsKey["SESSION_ID"] = "ssid";
    CommonRequestParamsKey["USER_ID"] = "urid";
    CommonRequestParamsKey["EVENT_NAME"] = "evnm";
    CommonRequestParamsKey["CLIENT_ID"] = "u";
    CommonRequestParamsKey["OMI_ID"] = "omi-id";
    CommonRequestParamsKey["TIBURON_ID"] = "tiburon-id";
})(CommonRequestParamsKey || (exports.CommonRequestParamsKey = CommonRequestParamsKey = {}));
var ExternalIdParamsKey;
(function (ExternalIdParamsKey) {
    ExternalIdParamsKey["APPSFLYER"] = "appsflyer-id";
    ExternalIdParamsKey["EXTERNAL_ID"] = "externalId";
    ExternalIdParamsKey["MY_TRACKER"] = "order_number";
    ExternalIdParamsKey["APP_METRICA"] = "OrderId";
})(ExternalIdParamsKey || (exports.ExternalIdParamsKey = ExternalIdParamsKey = {}));
var MediaTrafficSourceRequestParamsKey;
(function (MediaTrafficSourceRequestParamsKey) {
    MediaTrafficSourceRequestParamsKey["SITE_ID"] = "sid";
    MediaTrafficSourceRequestParamsKey["SUPERCAMPAIGN_ID"] = "scid";
    MediaTrafficSourceRequestParamsKey["CAMPAIGN_ID"] = "cid";
    MediaTrafficSourceRequestParamsKey["BANNER_ID"] = "crid";
    MediaTrafficSourceRequestParamsKey["DL_CODE"] = "dl";
    MediaTrafficSourceRequestParamsKey["SUB_1"] = "sub1";
    MediaTrafficSourceRequestParamsKey["SUB_2"] = "sub2";
    MediaTrafficSourceRequestParamsKey["SECTION"] = "section";
    MediaTrafficSourceRequestParamsKey["SOURCE_ID"] = "sourceid";
    MediaTrafficSourceRequestParamsKey["UTM_SOURCE"] = "utm_source";
    MediaTrafficSourceRequestParamsKey["UTM_MEDIUM"] = "utm_medium";
    MediaTrafficSourceRequestParamsKey["UTM_CAMPAIGN"] = "utm_campaign";
    MediaTrafficSourceRequestParamsKey["UTM_CONTENT"] = "utm_content";
})(MediaTrafficSourceRequestParamsKey || (exports.MediaTrafficSourceRequestParamsKey = MediaTrafficSourceRequestParamsKey = {}));
var DOMRequestParamsKey;
(function (DOMRequestParamsKey) {
    DOMRequestParamsKey["SCROLL_X"] = "dscrx";
    DOMRequestParamsKey["SCROLL_Y"] = "dscry";
    DOMRequestParamsKey["SCREEN_WIDTH"] = "dsrw";
    DOMRequestParamsKey["SCREEN_HEIGHT"] = "dsrh";
    DOMRequestParamsKey["SCREEN_AVAIL_WIDTH"] = "dsraw";
    DOMRequestParamsKey["SCREEN_AVAIL_HEIGHT"] = "dsrah";
    DOMRequestParamsKey["DOCUMENT_WIDTH"] = "docw";
    DOMRequestParamsKey["DOCUMENT_HEIGHT"] = "docy";
    DOMRequestParamsKey["TOP"] = "tp";
    DOMRequestParamsKey["DOM_AVALIABLE"] = "aval";
    DOMRequestParamsKey["LOCATION_URL"] = "locur";
    DOMRequestParamsKey["ANCESTOR_ORIGINS"] = "ancor";
    DOMRequestParamsKey["REFERRER_URL"] = "refur";
    DOMRequestParamsKey["NAVIGATION_START"] = "navst";
    DOMRequestParamsKey["HISTORY_LENGTH"] = "hisln";
    DOMRequestParamsKey["READY_STATE"] = "rst";
    DOMRequestParamsKey["PIXEL_RATIO"] = "pidr";
    DOMRequestParamsKey["PLATFORM"] = "dpl";
    DOMRequestParamsKey["MAX_TOUCH_POINTS"] = "dmtp";
    DOMRequestParamsKey["HARDWARE_CONCURRENCY"] = "dhcn";
    DOMRequestParamsKey["MEMORY"] = "dmem";
    DOMRequestParamsKey["IS_CHROME"] = "dich";
    DOMRequestParamsKey["ORIENTATION"] = "dori";
    DOMRequestParamsKey["TIMEZONE"] = "dtz";
    DOMRequestParamsKey["BLUETOOTH"] = "dblt";
    DOMRequestParamsKey["CONNECTION_TYPE"] = "dcot";
    DOMRequestParamsKey["CONNECTION_RTT"] = "dcor";
    DOMRequestParamsKey["CONNECTION_DOWNLINK"] = "dcod";
    DOMRequestParamsKey["BATTERY_CHARGING"] = "dbtc";
    DOMRequestParamsKey["BATTERY_LEVEL"] = "dbtl";
    DOMRequestParamsKey["MOTION"] = "dmot";
    DOMRequestParamsKey["WEBGL_RENDERER"] = "dwglr";
    DOMRequestParamsKey["WEBGL_VENDOR"] = "dwglv";
    DOMRequestParamsKey["VIDEO_INPUTS"] = "dvin";
    DOMRequestParamsKey["VIDEO_OUTPUTS"] = "dvot";
    DOMRequestParamsKey["AUDIO_INPUTS"] = "dain";
    DOMRequestParamsKey["AUDIO_OUTPUTS"] = "daot";
    DOMRequestParamsKey["WEBDRIVER"] = "dwdr";
    DOMRequestParamsKey["HAS_BEEN_ACTIVE"] = "dhba";
    DOMRequestParamsKey["IS_ACTIVE"] = "disa";
    DOMRequestParamsKey["PLUGINS_LENGTH"] = "dpll";
    DOMRequestParamsKey["MIME_TYPES_LENGHT"] = "dmtl";
    DOMRequestParamsKey["APP_VERSION"] = "dapv";
    DOMRequestParamsKey["LANGUAGES"] = "dlngs";
    DOMRequestParamsKey["COMMON_FONTS"] = "dcofs";
    DOMRequestParamsKey["DEVTOOLS_ATTACHED"] = "ddvat";
    DOMRequestParamsKey["PERMISSIONS"] = "dpers";
    DOMRequestParamsKey["PERMISSIONS_OVERRIDEN"] = "dpersa";
    DOMRequestParamsKey["WEBRTC_IP"] = "dwri";
    DOMRequestParamsKey["COOKIE_GA"] = "dckga";
    DOMRequestParamsKey["COOKIE_YA"] = "dckya";
    DOMRequestParamsKey["COOKIES_COUNT"] = "dckc";
    DOMRequestParamsKey["MRAID_AVAILABLE"] = "dmra";
})(DOMRequestParamsKey || (exports.DOMRequestParamsKey = DOMRequestParamsKey = {}));
var PlayerRequestParamsKey;
(function (PlayerRequestParamsKey) {
    PlayerRequestParamsKey["ID"] = "plrid";
    PlayerRequestParamsKey["WIDTH"] = "plrw";
    PlayerRequestParamsKey["HEIGHT"] = "plrh";
    PlayerRequestParamsKey["COORD_X"] = "prlcx";
    PlayerRequestParamsKey["COORD_Y"] = "prlcy";
    PlayerRequestParamsKey["MRC_STATUS"] = "plrmrc";
    PlayerRequestParamsKey["STICKY_HIDDEN"] = "plrhdn";
    PlayerRequestParamsKey["SRE"] = "plrsre";
    PlayerRequestParamsKey["VOLUME"] = "plrvol";
    PlayerRequestParamsKey["VISIBILITY"] = "plrvsb";
    PlayerRequestParamsKey["FIRST_FRAME_MS"] = "plrfms";
})(PlayerRequestParamsKey || (exports.PlayerRequestParamsKey = PlayerRequestParamsKey = {}));
var BidRequestParamsKey;
(function (BidRequestParamsKey) {
    BidRequestParamsKey["AD_FORMAT"] = "adformat";
    BidRequestParamsKey["TRAFFIC_TYPE"] = "traffictype";
})(BidRequestParamsKey || (exports.BidRequestParamsKey = BidRequestParamsKey = {}));
var BrandSafetyParamsKey;
(function (BrandSafetyParamsKey) {
    BrandSafetyParamsKey["CONTENT"] = "cntnt";
})(BrandSafetyParamsKey || (exports.BrandSafetyParamsKey = BrandSafetyParamsKey = {}));
var MediaEventShort;
(function (MediaEventShort) {
    MediaEventShort["LOADED"] = "ldd";
    MediaEventShort["LOADED_FILTERED"] = "ldf";
    MediaEventShort["STARTED"] = "str";
    MediaEventShort["STOPPED"] = "stp";
    MediaEventShort["SKIPPED"] = "skp";
    MediaEventShort["SKIPPABLE_STATE_CHANGE"] = "ssc";
    MediaEventShort["SIZE_CHANGE"] = "szc";
    MediaEventShort["LINEAR_CHANGE"] = "lnc";
    MediaEventShort["DURATION_CHANGE"] = "drc";
    MediaEventShort["EXPANDED_CHANGE"] = "exc";
    MediaEventShort["REMAINING_TIME_CHANGE"] = "rtc";
    MediaEventShort["VOLUME_CHANGE"] = "vlc";
    MediaEventShort["IMPRESSION"] = "imp";
    MediaEventShort["IMPRESSION_3"] = "imp3";
    MediaEventShort["IMPRESSION_F"] = "impf";
    MediaEventShort["VIDEO_START"] = "vst";
    MediaEventShort["VIDEO_FIRST_QUARTILE"] = "vfq";
    MediaEventShort["VIDEO_MIDPOINT"] = "vmp";
    MediaEventShort["VIDEO_THIRD_QUARTILE"] = "vtq";
    MediaEventShort["VIDEO_COMPLETE"] = "vcm";
    MediaEventShort["CLICK_THRU"] = "clt";
    MediaEventShort["INTERACTION"] = "int";
    MediaEventShort["USER_ACCEPT_INVITATION"] = "uai";
    MediaEventShort["USER_MINIMIZE"] = "umn";
    MediaEventShort["USER_CLOSE"] = "ucl";
    MediaEventShort["PAUSED"] = "psd";
    MediaEventShort["PLAYING"] = "pln";
    MediaEventShort["LOG"] = "log";
    MediaEventShort["ERROR"] = "err";
    MediaEventShort["MOUSEOVER"] = "mso";
    MediaEventShort["POST_CLICK"] = "pstc";
    MediaEventShort["POST_CLICK_3_SEC"] = "pstc3s";
    MediaEventShort["METADATA_LOADED"] = "mdld";
})(MediaEventShort || (exports.MediaEventShort = MediaEventShort = {}));
var MediaEvent;
(function (MediaEvent) {
    MediaEvent["LOADED"] = "loaded";
    MediaEvent["LOADED_FIX"] = "loaded_fix";
    MediaEvent["LOADED_FILTERED"] = "loaded_filtered";
    MediaEvent["STARTED"] = "started";
    MediaEvent["STOPPED"] = "stopped";
    MediaEvent["SKIPPED"] = "skipped";
    MediaEvent["SKIPPABLE_STATE_CHANGE"] = "skippable_state_change";
    MediaEvent["SIZE_CHANGE"] = "size_change";
    MediaEvent["LINEAR_CHANGE"] = "linear_change";
    MediaEvent["DURATION_CHANGE"] = "duration_change";
    MediaEvent["EXPANDED_CHANGE"] = "expanded_change";
    MediaEvent["REMAINING_TIME_CHANGE"] = "remaining_time_change";
    MediaEvent["VOLUME_CHANGE"] = "volume_change";
    MediaEvent["IMPRESSION"] = "impression";
    MediaEvent["IMPRESSION_3"] = "impression_3";
    MediaEvent["IMPRESSION_F"] = "impression_f";
    MediaEvent["VIDEO_START"] = "video_start";
    MediaEvent["VIDEO_FIRST_QUARTILE"] = "video_first_quartile";
    MediaEvent["VIDEO_MIDPOINT"] = "video_midpoint";
    MediaEvent["VIDEO_THIRD_QUARTILE"] = "video_third_quartile";
    MediaEvent["VIDEO_COMPLETE"] = "video_complete";
    MediaEvent["CLICK_THRU"] = "click_thru";
    MediaEvent["INTERACTION"] = "interaction";
    MediaEvent["USER_ACCEPT_INVITATION"] = "user_accept_invitation";
    MediaEvent["USER_MINIMIZE"] = "user_minimize";
    MediaEvent["USER_CLOSE"] = "user_close";
    MediaEvent["PAUSED"] = "paused";
    MediaEvent["PLAYING"] = "playing";
    MediaEvent["LOG"] = "log";
    MediaEvent["ERROR"] = "error";
    MediaEvent["MOUSEOVER"] = "mouseover";
    MediaEvent["POST_CLICK"] = "post_click";
    MediaEvent["POST_CLICK_3_SEC"] = "post_click_3_sec";
    MediaEvent["METADATA_LOADED"] = "metadata_loaded";
})(MediaEvent || (exports.MediaEvent = MediaEvent = {}));
var MRCStatusShort;
(function (MRCStatusShort) {
    MRCStatusShort["NOT_SUPPORT"] = "ns";
    MRCStatusShort["NOT_COMPLETE"] = "nc";
    MRCStatusShort["COMPLETE"] = "co";
})(MRCStatusShort || (exports.MRCStatusShort = MRCStatusShort = {}));
var MRCStatus;
(function (MRCStatus) {
    MRCStatus["NOT_SUPPORT"] = "not_support";
    MRCStatus["NOT_COMPLETE"] = "not_complete";
    MRCStatus["COMPLETE"] = "complete";
})(MRCStatus || (exports.MRCStatus = MRCStatus = {}));
exports.BASE64_PARAM_KEY = "pckdt";
var EventName;
(function (EventName) {
    EventName["HitReceived"] = "hits-recieved";
    EventName["ClickCPIAnalyzed"] = "cpi-clicks";
    EventName["PostbackCPIAnalyzed"] = "cpi-postbacks";
    EventName["SessionMediaAnalyzed"] = "media-sessions";
    EventName["PostbackMediaAnalyzed"] = "media-postbacks";
    EventName["TrafficCPIFormatted"] = "ch_traffic-cpi";
    EventName["TrafficMediaFormatted"] = "ch_traffic-media";
    EventName["SiteDictionaryFormatted"] = "ch_site-dictionary";
    EventName["SectionDictionaryFormatted"] = "ch_section-dictionary";
})(EventName || (exports.EventName = EventName = {}));
exports.TrafficSourceMediaParams = [
    "site",
    "supercampaign",
    "campaign",
    "banner",
    "dlcode",
    "sub1",
    "sub2",
    "section",
];
exports.TrafficSourceCPIParams = [
    "source",
    "tracker",
    "partner",
    "banner",
    "sub1",
    "sub2",
];
var TrackingSystem;
(function (TrackingSystem) {
    TrackingSystem["Adjust"] = "Adjust";
    TrackingSystem["AppsFlyer"] = "AppsFlyer";
    TrackingSystem["AppMetrica"] = "AppMetrica";
})(TrackingSystem || (exports.TrackingSystem = TrackingSystem = {}));
var AppMetricaApiEventType;
(function (AppMetricaApiEventType) {
    AppMetricaApiEventType["Event"] = "Event";
    AppMetricaApiEventType["Ecommerce"] = "E-commerce";
})(AppMetricaApiEventType || (exports.AppMetricaApiEventType = AppMetricaApiEventType = {}));
var AdTemplateType;
(function (AdTemplateType) {
    AdTemplateType[AdTemplateType["VPAID"] = 1] = "VPAID";
})(AdTemplateType || (exports.AdTemplateType = AdTemplateType = {}));
