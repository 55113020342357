import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import Vue from "vue";
import VueApollo from "vue-apollo";
import { API_URL } from "./config";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "auth_token_default";

const cache = new InMemoryCache();

function getAuthToken() {
  return localStorage?.getItem(AUTH_TOKEN);
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getAuthToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

export function createDefaultClient() {
  const terminatingLink = createUploadLink({
    uri: `${API_URL}/graphql`,
    headers: { "Apollo-Require-Preflight": "true" },
  });
  return new ApolloClient({
    link: authMiddleware.concat(terminatingLink),
    cache,
  });
}

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  return new VueApollo({
    defaultClient: createDefaultClient(),
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      console.log(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message,
      );
    },
  });
}
